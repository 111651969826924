import * as React from "react";
import { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import {
  Pagination,
  BulkDeleteWithConfirmButton,
  Button,
  Confirm,
  Filter,
  NullableBooleanInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  useNotify,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
} from "react-admin";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ReferenceManyField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import { TrailEdit } from "./trail.edit.component";

const PathBulkActionButtons = (props: any) => {
  const [selectedIds, setSelectedIds] = React.useState(props.selectedIds);
  const [isActive, setIsActive] = React.useState("");

  const [openStatusConfirm, setOpenStatusConfirm] = React.useState(false);
  const [openGroomedConfirm, setOpenGroomedConfirm] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();

  const [updateManyWithStatus, { loading: loadingManyWithStatus }] =
    useUpdateMany(
      "paths",
      selectedIds,
      { status: isActive },
      {
        onSuccess: () => {
          refresh();
          notify("Paths updated");
          unselectAll("paths");
        },
        onFailure: (error) =>
          notify(
            `Error: paths not updated with status: "${isActive}".`,
            "warning"
          ),
      }
    );

  const [updateManyAsGroomed, { loading: loadingManyAsGroomed }] =
    useUpdateMany(
      "paths",
      selectedIds,
      { groomedAt: new Date() },
      {
        onSuccess: () => {
          refresh();
          notify("Paths updated");
          unselectAll("paths");
        },
        onFailure: (error) =>
          notify(`Error: paths failed to update groom.`, "warning"),
      }
    );

  // const handleClick = () => setOpenStatusConfirm(true);
  const handleStatusDialogClose = () => setOpenStatusConfirm(false);

  const handleGroomedDialogClose = () => setOpenGroomedConfirm(false);

  const handleConfirmForStatus = () => {
    updateManyWithStatus();
    setOpenStatusConfirm(false);
  };

  const handleConfirmForGroomed = () => {
    updateManyAsGroomed();
    setOpenStatusConfirm(false);
  };

  React.useEffect(() => {
    setIsActive("");
    setSelectedIds(props.selectedIds);
  }, [props.selectedIds, selectedIds]);

  const handleChange = (event: any) => {
    console.log("status");
    setOpenStatusConfirm(true);
    setIsActive(event.target.value);
  };

  return (
    <Fragment>
      <FormControl style={{ position: "relative", top: "-8px" }}>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          Set status
        </InputLabel>
        <Select value={isActive} onChange={handleChange} displayEmpty>
          <MenuItem value="" disabled>
            <em>Select One</em>
          </MenuItem>
          <MenuItem value="open" selected>
            open
          </MenuItem>
          <MenuItem value="closed">closed</MenuItem>
          {/*<MenuItem value="freshlyGroomed">freshly groomed</MenuItem>*/}
        </Select>
        {/*<FormHelperText>Label + placeholder</FormHelperText>*/}
      </FormControl>
      <Confirm
        isOpen={openStatusConfirm}
        loading={loadingManyWithStatus}
        title="Update paths with status"
        content={`Are you sure you want set the status to "${isActive}" for these items?`}
        onConfirm={handleConfirmForStatus}
        onClose={handleStatusDialogClose}
      />
      <Confirm
        isOpen={openGroomedConfirm}
        loading={loadingManyAsGroomed}
        title="Update paths as groomed"
        content={`Are you sure you want to groom these items?`}
        onConfirm={handleConfirmForGroomed}
        onClose={handleGroomedDialogClose}
      />
      <Button
        color="primary"
        variant="outlined"
        label="Groomed"
        onClick={() => setOpenGroomedConfirm(true)}
      />
      {/*<Button label="Save" onClick={handleClick} />*/}
      <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
  );
};

const PathFilter = (props) => (
  <Filter {...props}>
    <TextInput
      label="Search"
      variant={"outlined"}
      source="q"
      alwaysOn
      resettable
    />
    {/*<ReferenceInput label="Area" source="areaId" target="id" reference="pathsareas" allowEmpty={false}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Category" source="pathCategoryId" target="id" reference="pathcategories" allowEmpty={false}>
            <SelectInput optionText="name" />
        </ReferenceInput>
        <SelectInput source="status" allowEmpty={false} choices={[
            { id: 'open', name: 'open' },
            { id: 'closed', name: 'closed' },
            // { id: 'freshlyGroomed', name: 'freshly groomed' },
        ]} />*/}
    {/*<Button variant="text" onClick={() => props.setFilters({})} label="Clear filters"/>*/}
  </Filter>
);

const PathPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

export const TrailList = (props: any) => {
  return (
    <List
      {...props}
      perPage={25}
      pagination={<PathPagination />}
      bulkActionButtons={<PathBulkActionButtons />}
      filters={<PathFilter />}
      exporter={false}
    >
      <Datagrid rowClick="edit" expand={TrailEdit}>
        <BooleanField source="visible" label="Visible?" />

        <TextField source="name" />
        <TextField source="length" />
      </Datagrid>
    </List>
  );
};

export default TrailList;
